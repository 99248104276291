<template>
  <div id="nav">
    <header class="p-1 bg-body rounded border-bottom border-primary border-2 static-top shadow-sm" style="--bs-bg-opacity: .90;">
      <!-- shadow-sm p-3 mb-3 bg-body rounded border-bottom -->
      
        <nav class="navbar navbar-expand-md navbar-light">
          <div class="container-fluid">
          <a class="navbar-brand" href="#">
            <img src="../assets/logo.png" />
          </a>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main-nav" aria-controls="main-nav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

          <div class="collapse navbar-collapse justify-content-end align-center"
            id="main-nav">
            <div class="navbar-nav mr-auto nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
              <router-link :to="{ name: 'Home' }" class="nav-item nav-link px-2 link-secondary">Home</router-link>
              
            <!-- <router-link :to="{ name: 'CreateDemoContent' }" class="nav-item nav-link px-2 link-secondary" v-if="userInfo && userInfo.document.admin">Create Demo Content</router-link> -->
            
              <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle nav-link px-2 link-secondary" href="#" 
          id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" v-if="user">
            Manage
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <router-link :to="{ name: 'ManageDemos' }" class="dropdown-item" v-if="user">Manage Demos</router-link>
            <router-link :to="{ name: 'CreateDemoContent' }" class="dropdown-item" v-if="user">Build Custom Demo</router-link>
              
          </ul>
        </li>


              <router-link :to="{ name: 'Help' }" class="nav-item nav-link px-2 link-secondary">Help</router-link>
            </div>


            <div class="dropdown text-end me-4">
            <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="bi bi-globe2"></i> Language
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li><a class="dropdown-item" @click="changeLanguage('en')">English</a></li>
              <li><a class="dropdown-item" @click="changeLanguage('es')">Español</a></li>
              <li><a class="dropdown-item" @click="changeLanguage('it')">Italiano</a></li>
              <li><a class="dropdown-item" @click="changeLanguage('ja')">日本語</a></li>
              <li><a class="dropdown-item" @click="changeLanguage('pt')">Português</a></li>
            </ul>
          </div>

            <div class="text-end" v-if="!user">
              <button @click="handleLogin" class="btn btn-outline-primary">Login with Google</button><div class="divider"/>
              <router-link :to="{ name: 'Register' }" class="btn btn-primary">Register</router-link>
            </div>
            <div class="dropdown text-end me-5" v-if="user">
              <a href="#" class="d-block link-dark text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
                <img :src="user.photoURL" width="32" height="32" class="rounded-circle" referrerpolicy="no-referrer">
              </a>
              <ul class="dropdown-menu text-small" aria-labelledby="dropdownUser1" style="">
                <li><a class="dropdown-item" @click="handleLogout">Sign out</a></li>
                
          </ul>
        </div>
          </div>
          </div>
        </nav>
      
    </header>
  </div>
</template>

<script>
import { auth } from '../firebase/config'
import { signOut } from 'firebase/auth'
import getUser from '../composables/getUser'
import useLogin from '../composables/useLogin'
import getDoc from '../composables/getDoc'
import translate from '../composables/translate'
import { useStore } from 'vuex'
// firebase imports
import { db } from '../firebase/config'
import { doc, updateDoc } from 'firebase/firestore'
import { computed, ref } from '@vue/reactivity'
import { useRouter } from 'vue-router'

export default {

  setup() {
    const router = useRouter()
    const { user, userInfo } = getUser()
    const { login } = useLogin()
    const handleLogout = () => {
      signOut(auth)
      router.push({ name: 'Home'})
    }
    const handleLogin = async () => {
      await login()
    }
    /* watchEffect(() => {
      if(!user.value) {
        router.push('/')
      }
    }) */    

    const store = useStore()

    const lang = computed(() => store.state.lang)

    const updateLang = (l) => {
      store.commit('updateLang', l)
    }

    const changeLanguage = async (language) => {
      console.log('language',language)
      if(user.value){
        const docRef = doc(db, 'users', user.value.uid)
      await updateDoc(docRef, {
        language: language
      })
      }
      else {
        await updateLang(language)
      }
      router.go()
      //window.location.reload()
    }


    return { handleLogout, user, handleLogin, changeLanguage, lang, updateLang, userInfo }
  }

}
</script>

<style>
/* header {
    
    box-shadow: 0 2px 6px 0 rgba(0,0,0,.12);
    
} */
.divider{
    width:5px;
    height:auto;
    display:inline-block;
}
  
</style>