<template>
<section class="mt-3">
    <div class="container-lg">
        <div class="row">
        <div>
            <router-link :to="{ name: 'Home'}" class="btn btn-outline-primary border-0 mb-5"><i class="bi bi-arrow-left"></i> {{ translated.home }}</router-link>
            
        </div>
        
    </div>
    <div class="row">

        <div class="row"><h1 class="display-4">{{ translated.title }}</h1></div>
        <p class="lead">{{ translated.p1 }} <strong>{{trainings.sessionName}}</strong> {{ translated.p2 }} 🎉</p>
        <p>{{ translated.p3 }}</p>
        <div class="col">
        <h4><i class="bi bi-card-text"></i> {{ translated.overview}}</h4>
        <table class="table table-striped">
            <tbody>
                <tr>
                <td>{{ translated.sessionName}}:</td>
                <td>{{trainings.sessionName}}</td>
            </tr>
            <tr>
                <td>{{ translated.demoName}}:</td>
                <td>{{ trainings.demoName}}</td>
            </tr>
            <tr>
                <td>{{ translated.startDate}}:</td>
                <td>{{ moment.tz(trainings.startDate.toDate(), trainings.timezone) }}</td>
            </tr>
            <tr>
                <td>{{ translated.endDate}}:</td>
                <td>{{ moment.tz(trainings.endDate.toDate(), trainings.timezone)}}</td>
            </tr>
            <tr v-if="trainings.actions.duetAI">
                <td>Gemini start</td>
                <td>{{ moment.tz(trainings.duetAIstartDate.toDate(), trainings.timezone) }}</td>
            </tr>
            <tr v-if="trainings.actions.duetAI">
                <td>Gemini end</td>
                <td>{{ moment.tz(trainings.duetAIendDate.toDate(), trainings.timezone) }} <small v-if="timeDifferenceInMinutes(trainings.duetAIendDate) <= 0" class="text-muted">Expired</small></td>
            </tr>
            <tr>
                <td>{{ translated.language}}:</td>
                <td>{{ languages.filter((lang)=> lang.code === trainings.language)[0].localeInLanguage }}</td>
            </tr>
            <tr>
                <td>{{ translated.demoAssets}}:</td>
                
                
                <td v-if="trainings.status.includes(`Content copied`)"><a :href="'https://drive.google.com/drive/folders/'+trainings.folder" target="_blank" style="padding-left: 0px;">{{trainings.sessionName}} Folder <i class="bi bi-box-arrow-up-right"></i></a></td>
                <td v-else>{{translated.creatingDemo.resources}} <div class="spinner-border spinner-border-sm" role="status"><span class="visually-hidden">Loading...</span></div>
</td>
            </tr>
            <tr v-for="classroom in classrooms" :key="classroom">
                    <td v-if="trainings.classroom ">Google Classroom:</td>
                <td v-if="trainings.classroom "><a :href="classroom.alternateLink" target="_blank" style="padding-left: 0px;">{{ classroom.name}} <i class="bi bi-box-arrow-up-right"></i></a></td>
            
                
                </tr>
            <tr v-if="trainings.classroom  && !trainings.status.includes(`Classroom created`)">
                <td>Google Classroom:</td>
                <td>
                {{translated.creatingDemo.classroom}} <div class="spinner-border spinner-border-sm" role="status"><span class="visually-hidden">Loading...</span></div>
            </td>
            </tr>    
            </tbody>
            
        </table>
        
        </div>
        <div class="col">
        <div v-if="trainings.users">
            <h4><i class="bi bi-people"></i> {{translated.userDetails}}</h4>
        
        <table class="table table-striped">
            <tbody>
                <tr>
                <td>{{translated.usernames}}:</td>
                <td>
                    <div v-if="trainings.status.includes(`Users created`)">
                        {{ trainings.userName }}1@<span v-if="trainings.demoName == 'Google Workspace Security Demo'">admin.</span>{{ domain }} to {{trainings.userName+trainings.userNumber}}@<span v-if="trainings.demoName == 'Google Workspace Security Demo'">admin.</span>{{ domain }} <i class="bi bi-check-circle-fill"></i>

                    </div>
                    <div v-else>
                        {{translated.creatingDemo.users}} {{trainings.userName}}1 to {{trainings.userName+trainings.userNumber}} <div class="spinner-border spinner-border-sm" role="status"><span class="visually-hidden">Loading...</span></div>

                    </div>
                </td>
            </tr>
            <tr>
                <td>{{translated.password}}:</td>
                <td>gogoogle1</td>
            </tr>
            <tr>
                <td>{{translated.numberOfUsers}}:</td>
                <td>{{trainings.userNumber}}</td>
            </tr>
            <tr>
                <td>{{translated.license}}:</td>
                <td>{{ licenses.filter((lic)=> lic.skuId === trainings.userLicense)[0].name}}</td>
            </tr>
            <tr>
                <td>Group: </td>
                <td>{{ trainings.userName }}-group@demogfe.com</td>
            </tr>
            </tbody>
            
        </table>
        </div>
        </div>
        <div class="row mt-4">
        <h4><i class="bi bi-folder"></i> {{ translated.resources }}</h4>

        <div class="accordion" id="accordionPanelsStayOpenExample">
            <div class="accordion-item" v-if="trainings.demoName == 'Google Workspace Security Demo'">
            <h2 class="accordion-header" id="panelsStayOpen-headingThree">
            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                <img src="../../assets/icons/admin.png" width="28" class="me-2"/>Google Admin Console
            </button>
            </h2>
            <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingThree">
            <div class="accordion-body">
                <h3>Google Workspace Security Demo Login Instructions</h3>
<hr>

<div>
    <h5>{{ translated.accountDetails }}</h5>
    <div class="col-lg-8">
    <div class="input-group mb-4">
    <span class="input-group-text">
        <strong>Username</strong>
    </span>
    <input type="text" class="form-control" v-model="adminUser" @focus="() => copyToClipboard(adminUser)" readonly>
    <!-- tooltip -->
    <span class="input-group-text" @click="() => copyToClipboard(adminUser)">
        
        <i :class="iconState === 'clipboard' ? 'bi bi-clipboard' : 'bi bi-clipboard-check'" ></i>
        
    </span>
</div>
<div class="input-group mb-4">
    <span class="input-group-text">
        <strong>Password</strong>
    </span>
    <input type="text" class="form-control" v-model="adminPassword" @focus="() => copyToClipboard2(adminPassword)" readonly>
    <!-- tooltip -->
    <span class="input-group-text" @click="() => copyToClipboard2(adminPassword)">
        
        <i :class="iconState2 === 'clipboard' ? 'bi bi-clipboard' : 'bi bi-clipboard-check'"></i>
        
    </span>
</div>
    </div>

</div>

<h5>Logging In:</h5>
<ol>
    <li>Navigate to the <a href="https://admin.google.com" target="_blank">Google Workspace Admin Console</a>.</li>
    <li>Use the above username and password to sign in.</li>
</ol>

<hr>

<p><strong>Special Note:</strong></p>
<p>Your demo account has been assigned an <strong>Admin Role</strong>. This role grants you access to demonstrate advanced security features within Google Workspace, such as the Security Dashboard and Investigation Tool.</p>
<p>Remember, this is a temporary account for demo purposes only. Avoid storing personal or sensitive information.</p>

                
                </div>
            </div>
        </div>
        <div class="accordion-item" v-if="trainings.demoName == 'Gemini for Workspace'">
            <h2 class="accordion-header" id="panelsStayOpen-headingThree">
            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                <img src="../../assets/icons/drive.png" width="28" class="me-2"/>Gemini for Workspace
            </button>
            </h2>
            <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingThree">
            <div class="accordion-body">
<div>
    <h3>Gemini for Workspace activity overview</h3>
    <p>
        We’ll demonstrate the predictability of Large Language Models by setting a scenario similar to Family Feud. We’ll ask the audience to provide some suggestions to a prompt. The prompt should include some elements which can lead to easy predictability. Suggesting randomization in the prompt makes it harder to predict an outcome. We’ll run the prompt and identify which of the audience suggestions match the output of the model. If we have no match we can groupthink around why the model may have given the information it did.         
    </p>
    <p>
        The ultimate intention here is to emphasize model predictability. It takes a human to provide true creativity. These models are helpful for kick starting creativity but should be evaluated by those using them.
    </p>
    <div>
        <a :href="'https://drive.google.com/drive/folders/'+trainings.folder" class="btn btn-outline-primary mb-4" target="_blank" >Open Gemini for Workspace resources folder <i class="bi bi-box-arrow-up-right"></i></a>
    </div>
    <button class="btn btn-success btn-lg mt-4" v-if="timeDifferenceInMinutes(userInfo.document.duetAITime) >= 0">Gemini for Workspace available for <CountdownTimer :startingMinutes="timeDifferenceInMinutes(userInfo.document.duetAITime)" /></button>


    
</div>



                
                </div>
            </div>
        </div>
            <div class="accordion-item" v-if="trainings.status.includes(`Content copied`)">
            <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
            <button class="accordion-button " type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                <img src="../../assets/icons/drive.png" width="28" class="me-2"/> {{translated.trainingMaterials}}
            </button>
            </h2>
            <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingTwo">
            <div class="accordion-body">
                <p>{{translated.folderP1}} <strong><a :href="'https://drive.google.com/drive/folders/'+trainings.folder" target="_blank">{{trainings.sessionName}} Folder <i class="bi bi-box-arrow-up-right"></i></a></strong> {{translated.folderP2}}</p>
         <a :href="'https://drive.google.com/drive/folders/'+trainings.folder" class="btn btn-outline-primary mb-4" target="_blank" >{{ translated.goTo}} {{ trainings.sessionName}} <i class="bi bi-box-arrow-up-right"></i></a>
</div>
            </div>
        </div>
        <div class="accordion-item" v-if="trainings.classroom">
            <h2 class="accordion-header" id="panelsStayOpen-headingOne">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                <img src="../../assets/icons/classroom.png" width="28" class="me-2"/> Google Classroom
            </button>
            </h2>
            <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse " aria-labelledby="panelsStayOpen-headingOne">
            <div class="accordion-body">
                <p>{{ translated.classroomDescription}} 
        <span v-if="trainings.users">{{translated.classroomUsers}}</span>
        
        </p>
        <p>
            <span v-if="trainings.actions.originality">{{ translated.classroomOriginality}} <span v-if="trainings.demoName == 'Google Workspace & Chromebooks'"><i>Mission to Mars</i></span><span v-if="trainings.demoName == 'Classroom add-ons demo'"><i>Energy-Saving Tips</i></span> {{ translated.classroomOriginality2}}<i>Check plagiarism (originality)</i>.</span>
        </p>
        <div v-for="classroom in classrooms" :key="classroom">
                    <a :href="classroom.alternateLink" class="btn btn-outline-primary mb-4" target="_blank" >{{ translated.goTo}} {{ classroom.name}} <i class="bi bi-box-arrow-up-right" v-if="classroom.name != 'Space'"></i><span v-if="classroom.name =='Space'">🚀</span></a>

        </div>
        </div>
            </div>
        </div>

        <div class="accordion-item" v-if="trainings.actions.classVisit">
            <h2 class="accordion-header" id="panelsStayOpen-headingFour">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="true" aria-controls="panelsStayOpen-collapseFour">
                <img src="../../assets/icons/classroom.png" width="28" class="me-2"/> Class visits
            </button>
            </h2>
            <div id="panelsStayOpen-collapseFour" class="accordion-collapse collapse " aria-labelledby="panelsStayOpen-headingFour">
            <div class="accordion-body">
                <p>You have been assigned an admin role that allows you to temporarily access classes, without being assigned as a permanent co-teacher.</p>
                <p>To demo how to visit a class:</p>
                <ol>
                    <li>Go to <a href="https://classroom.google.com/a/demogfe.com">Google Classroom</a></li>
                    <li>On the homepage or sidebar, click <strong>Visit a class</strong>.</li>
                    <li>To find a class to visit, search for one of the following users:
                        <ul>
                            <li>Teacher: Jackson Goldstein - jgoldstein@classroomacademy.demogfe.com</li>
                            <li>Student: Carly Sedayu - carlys@classroomacademy.demogfe.com</li>
                        </ul>
                    </li>
                </ol>
                <p><a href="https://support.google.com/edu/classroom/answer/13651766" class="btn btn-outline-primary mb-4" target="_blank" >Learn more about class visits</a></p>
            </div>
            </div>
        </div>

        <div class="accordion-item" v-if="trainings.actions.duetAI">
            <h2 class="accordion-header" id="panelsStayOpen-headingFive">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFive" aria-expanded="true" aria-controls="panelsStayOpen-collapseFive">
                <img src="../../assets/icons/drive.png" width="28" class="me-2"/> Gemini for Workspace
            </button>
            </h2>
            <div id="panelsStayOpen-collapseFive" class="accordion-collapse collapse " aria-labelledby="panelsStayOpen-headingFive">
            <div class="accordion-body">
                <p>You and your student users have been assigned a Gemini for Workspace license.</p>
                
                <p><a href="https://workspace.google.com/blog/product-announcements/duet-ai" class="btn btn-outline-primary mb-4" target="_blank" >Learn more about Gemini for Workspace</a></p>
            </div>
            </div>
        </div>

        <div class="accordion-item" v-if="trainings.users && trainings.demoName != 'Google Workspace Security Demo'">
            <h2 class="accordion-header" id="panelsStayOpen-headingThree">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                <img src="../../assets/icons/admin.png" width="28" class="me-2"/>Google Admin Console
            </button>
            </h2>
            <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
            <div class="accordion-body">
                {{ translated.adminP1}} <a href="https://admin.google.com" target="_blank">Google Admin Console</a> {{translated.adminP2}}
                <ul>
                    <li>{{trainings.orgPath}}/{{trainings.userName}}</li>
                    
                </ul>
                
                </div>
            </div>
        </div>
        </div>
       
        </div>
        <div class="row mt-2">
            <div class="col-7">
            <button type="button" class="btn btn-secondary border-0 ms-3" data-bs-toggle="modal" data-bs-target="#resetDemo">
                    <i class="bi bi-arrow-clockwise"></i> Recreate demo session
                    </button>
                        <button type="button" class="btn btn-primary border-0 ms-3" data-bs-toggle="modal" data-bs-target="#resetAccounts">
                    <i class="bi bi-arrow-clockwise"></i> Reset Demo session Users
                    </button>
                    
            </div>
            
        </div>
        
    </div>

    
    <!-- Delete Content Modal -->
<div class="modal fade" id="resetDemo" tabindex="-1" aria-labelledby="resetDemo" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 ref="modalTitle" class="modal-title" id="resetDemo">Reset demo</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      
        
      <div class="modal-body">
          <p>Resetting a demo will delete and recreate the demo assets, Google Classroom, and users using your existing settings.</p>
          <p>Are you sure you wish to reset {{ trainings.sessionName}} demo?</p>
    
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
        <button type="submit" class="btn btn-danger" data-bs-dismiss="modal" @click="resetDemo()">Reset</button>
        
      </div>
      
    </div>
  </div>
</div>


<div class="modal fade" id="resetAccounts" tabindex="-1" aria-labelledby="resetAccounts" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 ref="modalTitle" class="modal-title" id="resetAccounts">Reset demo</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      
        
      <div class="modal-body">
          <p>Resetting Google Drive and Gmail will delete all items in the demo user accounts.</p>
          <p>Are you sure you wish to reset Drive and Gmail for {{ trainings.sessionName}} demo users?</p>
    
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
        <button type="submit" class="btn btn-danger" data-bs-dismiss="modal" @click="resetAccounts()">Reset</button>
        
      </div>
      
    </div>
  </div>
</div>


    </div>
    
</section>
  
</template>

<script>
import axios from "axios";
import getCollection from '@/composables/getCollection'
import getSubCollection from '@/composables/getSubCollection'
import translate from '@/composables/translate'
//import { useRouter } from 'vue-router'
import moment from 'moment'
// import DemoContentTable from '@/components/DemoContentTable.vue'
import getUser from '@/composables/getUser'
import getDoc from '@/composables/getDoc'
import { computed, ref } from '@vue/reactivity';
import { watch } from 'vue';
import { useStore } from 'vuex'
import CountdownTimer from '@/components/CountdownTimer.vue';
// firebase imports
//import { db } from '@/firebase/config'
//import { writeBatch, doc, addDoc, deleteDoc, updateDoc, collection, serverTimestamp } from 'firebase/firestore'

export default {
    props: ['id'],
    setup(props){
        const { user,userInfo } = getUser()
        const store = useStore()
        const lang = computed(() => store.state.lang)
        const { document: trainings } = getDoc('trainings',props.id)
        const { documents: classrooms } = getSubCollection('trainings',props.id,'classrooms','','')
        

        let domain = 'demogfe.com'
        if (trainings.demoName == 'Google Workspace Security Demo') {
            domain = 'admin.demogfe.com'
        }
        
        const adminUser = ref()
        const adminPassword = ref('gogoogle1')
        const demoId = ref()
        let demoClassrooms = ref([])

        watch(trainings, (newVal) => {
            console.log('updated trainings', newVal.userName);
            adminUser.value = `${newVal.userName}-admin@admin.demogfe.com`
            demoId.value = newVal.demoId
            const { documents: demoResources } = getSubCollection('demos',demoId.value,'content',['fileType','==','classroom'],'')
            demoClassrooms.value = demoResources
            console.log('demoResources',demoResources)
            console.log('demoClassrooms',demoClassrooms)
        })
        

        
        const { getLanguage, translated } = translate()
        getLanguage('sessionDetails',lang)

        const languages = ref([])
        axios.get('/data/locale.json')
        .then((res) => {
        languages.value = res.data.languages
        })

                const timeDifferenceInMinutes = (duetAITime) => {
            // Assuming duetAITime is a Firestore Timestamp
            // Convert it to a JavaScript Date object
            const duetAIDate = duetAITime.toDate();

            // Add 12 hours to the duetAITime
            duetAIDate.setHours(duetAIDate.getHours() + 12);

            // Get the current date and time
            const now = new Date();

            // Calculate the difference in milliseconds
            const diffInMs = duetAIDate - now;

            // Convert difference to minutes (Note: 1 minute = 60,000 milliseconds)
            const diffInMinutes = Math.floor(diffInMs / (1000 * 60));

            return diffInMinutes;
        };

        const licenses = [
        { productId: '101031', skuId: '1010310005', name: 'Google Workspace for Education Standard', total: 0 },
        { productId: '101031', skuId: '1010310006', name: 'Google Workspace for Education Standard (Staff)', total: 0 },
        { productId: '101031', skuId: '1010310007', name: 'Google Workspace for Education Standard (Extra Student)', total: 0 },
        { productId: '101031', skuId: '1010310008', name: 'Google Workspace for Education Plus', total: 10000, default: true },
        { productId: '101031', skuId: '1010310009', name: 'Google Workspace for Education Plus (Staff)', total: 10000 },
        { productId: '101031', skuId: '1010310010', name: 'Google Workspace for Education Plus (Extra Student)', total: 0 },
        { productId: '101037', skuId: '1010370001', name: 'Google Workspace for Education: Teaching and Learning Upgrade', total: 10000 },
        { productId: '101031', skuId: '1010310002', name: 'Google Workspace for Education Plus - Legacy', total: 10000 },
        { productId: '101031', skuId: '1010310003', name: 'Google Workspace for Education Plus - Legacy (Student)', total: 0 },
        { productId: 'none', skuId: 'none', name: 'Google Workspace for Education Fundamentals', total: 1000000 }
        ]

        function resetDemo() {
            axios.get('https://us-central1-demotool-e1a5e.cloudfunctions.net/resetDemo?docId='+props.id)
            }


            function resetAccounts() {
            axios.get('https://us-central1-demotool-e1a5e.cloudfunctions.net/resetUsers?docId='+props.id)
            }    
        
        
            const iconState = ref('clipboard'); // initial state is 'clipboard'
            const iconState2 = ref('clipboard');

            function copyToClipboard(value) {
            // Use the passed value for the clipboard operation
            navigator.clipboard.writeText(value);

            iconState.value = 'tick';

            setTimeout(() => {
                iconState.value = 'clipboard';
            }, 2000);
        }
        function copyToClipboard2(value) {
            // Use the passed value for the clipboard operation
            navigator.clipboard.writeText(value);

            iconState2.value = 'tick';

            setTimeout(() => {
                iconState2.value = 'clipboard';
            }, 2000);
        }

     

        // const newDate = moment(trainings.value.createdAt).add(1,'m').toDate()

        return { props, user, userInfo, trainings, moment, classrooms, licenses, languages, translated, resetDemo, domain, timeDifferenceInMinutes, adminUser, copyToClipboard, iconState, adminPassword, copyToClipboard2, iconState2, demoClassrooms, resetAccounts }
    },
    components: {
    //  "demo-content-cards": DemoContentCards,
    // "accordion": Accordion,
        
        CountdownTimer

  },
}
</script>
    
<style>

</style>